
import { defineComponent, ref, reactive } from "vue";
import TopBar from "@/components/TopBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";
import ChatBox from "@/components/productsBox.vue";
import BackTop from "@/components/BackTop.vue";
import CookieBox from "@/components/CookieBox.vue";
import { ElMessage } from "element-plus";
import { getEmail } from "@/api/contact";

export default defineComponent({
  name: "Career",
  components: {
    TopBar,
    Breadcrumb,
    Footer,
    ChatBox,
    BackTop,
    CookieBox,
  },
 metaInfo: {
    title: "B2B Marketplace - Chemicals & Steel - OTIMES",
    meta: [
      {
        name: "description",
        content: "Join our otimes family",
      },
      {
        name: "keywords",
        content:
          "OTIMES,PLA,PC,POM,PA,ABS,PP,Engineering Plastics,PLASTIC MATERIALS,STEEL,Cold Rolled Steel,Silicon Steel",
      },
    ],
  },
  setup() {
    const firstName = ref("");
    const lastName = ref("");
    const emailAddress = ref("");
    const content = ref("");
    const succeedTag = ref(0);
    const params = reactive({
      content: content,
      startDate: null,
      endDate: null,
      firstName: firstName,
      secondName: lastName,
      email: emailAddress,
    });
    const msg = () => {
      ElMessage({
        message: "Please enter your information.",
        type: "error",
      });
    };

    const getMessage = () => {
      if (
        firstName.value &&
        lastName.value &&
        emailAddress.value &&
        content.value
      ) {
        getEmail(params);
        succeedTag.value = 1;
        firstName.value = "";
        lastName.value = "";
        emailAddress.value = "";
        content.value = "";
      } else {
        msg();
      }
    };

    return {
      firstName,
      lastName,
      emailAddress,
      content,
      succeedTag,
      msg,
      params,
      getMessage,
    };
  },
});
