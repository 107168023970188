
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Breadcrumb',
  props: {
    breadcrumbList: {
      type: Array,
      default: () => []
    },
    showRouteName: {
      type: Boolean,
      default: true
    }
  },
  setup () {


    return {

    }
  }
});
