import http from "@/utils/http";

export const getEmail = (data) => {
  return http.request({
    url: "/foreign/messageRecord/add",
    method: "post",
    params: data,
  });
};

//主页商品展示（四条）
export const getDetail = (data) => {
  return http.request({
    url: "/zb/albbInfo/selectAll",
    method: "get",
    params: data,
  });
};

//商品分类菜单
export const getMenu = (data) => {
  return http.request({
    url: "/zb/albbInfo/selectAllCategory",
    method: "post",
    params: data,
  });
};

//推荐商品列表
export const getRecommendList = (data) => {
  return http.request({
    url: "/zb/albbInfo/selectRecommend",
    method: "post",
    params: data,
  });
};

//某一类商品列表
export const selectCagegoryInfo = (data) => {
  return http.request({
    url: "/zb/albbInfo/selectCategoryInfo",
    method: "post",
    params: data,
  });
};
